@keyframes mymove {
  from {
    right: 0px;
  }
  to {
    right: 10px;
  }
}

@keyframes blink-animation {
  0% {
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes blink-animation {
  0% {
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeUp {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@-webkit-keyframes fadeUp {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@-moz-keyframes fadeUp {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@-o-keyframes fadeUp {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeDown {
  0% {
    opacity: 0;
    transform: translateY(-30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@-webkit-keyframes fadeDown {
  0% {
    opacity: 0;
    transform: translateY(-30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@-moz-keyframes fadeDown {
  0% {
    opacity: 0;
    transform: translateY(-30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@-o-keyframes fadeDown {
  0% {
    opacity: 0;
    transform: translateY(-30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/************************************************************
Spinner Element
*************************************************************/
.loading {
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 2px solid rgba(102, 92, 121, 0.3);
  border-radius: 50%;
  border-top-color: #2d67a6;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;

  &.loading-lg {
    width: 20px;
    height: 20px;
  }
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}


// .loading-qc {
//   display: inline-block;
//   width: 80px;
//   height: 80px;
//   border: 12px solid #D9D9D9;
//   border-radius: 50% !important;
//   border-image: linear-gradient(white, white),linear-gradient(to right, #013B5C, #D9D9D9);
//   border-top-color: #013B5C;


//   animation: spin 2s linear infinite;
//   -webkit-animation: spin 2s linear infinite;

// }

.loading-qc {
  position: relative;
  width: 90px;
  height: 90px;
  // padding: 20px 20px;
  margin: 5px;
  display: inline-block;
    animation: spin 3s linear infinite;
  -webkit-animation: spin 3s linear infinite;

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 50%;
    padding: 12px; /* control the border thickness */
    background: linear-gradient(180deg, #013B5C, #D9D9D9);
    -webkit-mask: 
      linear-gradient(#fff 0 0) content-box, 
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    pointer-events: none;
  }
}