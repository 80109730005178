/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 */

 /* Do not change these file location, they're needed for SCSS features (mixins, includes, extend, etc) */
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  src: url("/assets/Lato-Regular.ttf");
}

@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 200;
  src: url("/assets/Lato-Light.ttf");
}

@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  src: url("/assets/Lato-Bold.ttf");
}

@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  src: url("/assets/Lato-Black.ttf");
}

@mixin border-radius {
  border-radius: 10px !important;
}

html,
body {
  font-family: "Lato";
  background-color: var(--nav-primary);
}

h1 {
  font-size: var(--nav-font36) !important;
}

h2 {
  font-size: var(--nav-font24) !important;
}

h3 {
  font-size: var(--nav-font18) !important;
}

h4 {
  font-size: var(--nav-font16) !important;
}

h5 {
  font-size: var(--nav-font14) !important;
}

h6 {
  font-size: var(--nav-font12) !important;
}

.nav-rounded {
  @include border-radius;
}

.nav-border1 {
  border: 1px solid var(--nav-grey1);
}

.nav-border2 {
  border: 1px solid var(--nav-grey2);
}

.nav-border3 {
  border: 1px solid var(--nav-grey3);
}

.nav-border4 {
  border: 1px solid var(--nav-grey4);
}

.nav-border-white {
  border: 1px solid var(--nav-true-white);
}

.nav-border-primary {
  border: 1px solid var(--nav-primary);
}

.nav-border-secondary {
  border: 1px solid var(--nav-secondary);
}

.nav-bg-accent-blue {
  background-color: var(--nav-accent-blue) !important;
}

.nav-bg-primary {
  background-color: var(--nav-primary) !important;
}

.nav-bg-grey1 {
  background-color: var(--nav-grey1) !important;
}

.nav-bg-grey2 {
  background-color: var(--nav-grey2) !important;
}

.nav-bg-grey3 {
  background-color: var(--nav-grey3) !important;
}

.nav-bg-grey4 {
  background-color: var(--nav-grey4) !important;
}

.nav-bg-snow-white {
  background-color: var(--nav-snow-white) !important;
}

.nav-bg-error {
  background-color: var(--nav-error) !important;
}

.nav-bg-success {
  background-color: var(--nav-success) !important;
}

.nav-primary {
  color: var(--nav-primary);
}

.nav-accent-blue {
  color: var(--nav-accent-blue);
}

.nav-secondary {
  color: var(--nav-secondary);
}

.nav-error {
  color: var(--nav-error);
}

.nav-success {
  color: var(--nav-success);
}

.nav-warning {
  color: var(--nav-warning);
}

.nav-medium-risk {
  color: #CBA300;
}

.nav-snow-white {
  color: var(--nav-snow-white);
}

.nav-link {
  font-weight: 500;
  cursor: pointer;

  &:hover {
    text-decoration: underline !important;
  }
}

.nav-btn-primary {
  background: var(--nav-true-white);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  @include border-radius;
  border: 1px solid rgba(0, 119, 188, 0.3);
  color: var(--nav-secondary);
  padding: 5px 8px;

  &:hover {
    color: var(--nav-secondary);
    background-color: rgb(0, 119, 188, 0.3);
    border: 1px solid rgba(0, 119, 188, 0.3);
  }
}

.nav-btn-accent {
  background: var(--nav-accent-blue);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  @include border-radius;
  border-color: var(--nav-accent-blue);
  color: var(--nav-primary);
  padding: 5px 8px;

  &:hover {
    color: var(--nav-primary);
  }
}

.nav-btn-error {
  background: var(--nav-error);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  @include border-radius;
  border-color: var(--nav-error);
  color: var(--nav-primary);
  padding: 5px 8px;

  &:hover {
    color: var(--nav-primary);
  }
}

.nav-btn-disabled {
  background: var(--nav-grey2);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  @include border-radius;
  border-color: var(--nav-grey2);
  color: var(--nav-primary);
  pointer-events: none;
  padding: 5px 8px;

  &:hover {
    color: var(--nav-primary);
  }
}

.font-weight-light {
  font-weight: 200 !important;
}

.font-weight-bold {
  font-weight: 600 !important;
}

.font-weight-black {
  font-weight: 700 !important;
}

select,
input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]) {
  &.form-control-nav {
    @include border-radius;
    padding: 4px 8px;
    border: 1px solid var(--nav-grey1);

    &::placeholder {
      color: var(--nav-grey3);
    }
  }

  &.form-control-sm {
    height: 30px;
  }
}

.or-checkbox {
  display: flex;
  justify-content: center;
  width: 100%;

  input[type="checkbox"] {
    opacity: 0;
    display: none;
    box-sizing: border-box;

    &:checked+label::before {
      font-family: "Font Awesome 6 Pro";
      content: "";
      text-align: center;
      font-weight: 600;
      line-height: 1.4;
      background-color: var(--nav-accent-blue);
      border-color: var(--nav-accent-blue);
      color: white;
    }

    &:disabled+label::before {
      background-color: #e9ecef;
    }
  }

  label {
    display: inline-block;
    position: relative;
    cursor: pointer;
    font-size: 10px;
    color: var(--nav-primary);

    &:before {
      content: "";
      display: inline-block;
      position: absolute;
      width: 16px;
      height: 16px;
      top: 2px;
      left: 0px;
      border: 1px solid var(--nav-accent-blue);
      border-radius: 3px;
      background-color: var(--nav-primary);
      transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
    }

    span {
      margin-left: 30px;
    }
  }
}

.nav-modal-style {
  .modal-content {
    background-color: var(--nav-true-white);
    box-shadow: 0px 4px 4px rgba(1, 59, 92, 0.16);
    @include border-radius;
    border: 0;

    .modal-header {
      @include border-radius;
      background-color: var(--nav-true-white);
      border: 0;
    }

    .modal-body {
      padding: 10px 25px;
    }

    .modal-footer {
      border: 0;
      width: 100%;
      margin-bottom: 5px;
      @include border-radius;
      background-color: var(--nav-true-white);
    }
  }
}

.nav-modal {
  @media (min-width: 544px) {
    .modal-lg {
      max-width: 1200px;
    }

    .modal-md {
      max-width: 700px;
    }

    .modal-sm {
      max-width: 350px;
    }

    .modal-full {
      max-width: 95%;
      height: 90vh;

      .modal-body {
        max-height: 90vh;
        overflow: scroll;
      }
    }
  }
}

.modal-backdrop:has(+ .nav-modal-style) {
  &.show {
    opacity: 0.8 !important;
    background-color: var(--nav-grey1);
  }
}

.modal-backdrop:has(+ .modal .nav-sidebar-modal) {
  width: 435px !important;
}

.modal:has(.nav-sidebar-modal) {
  pointer-events: none !important;
}

@media only screen and (min-width: 768px) {
  .nav-sidebar-modal {
    top: 25%;
    left: calc(293px + -53.5%);
  }
}

@media only screen and (min-width: 992px) {
  .nav-sidebar-modal {
    top: 25%;
    left: calc(297px + -53.5%);
  }
}

@media only screen and (min-width: 1200px) {
  .nav-sidebar-modal {
    top: 25%;
    left: calc(313px + -53.5%);
  }
}

@media only screen and (min-width: 1600px) {
  .nav-sidebar-modal {
    top: 25%;
    left: calc(326px + -53.5%);
  }
}

@media only screen and (min-width: 2100px) {
  .nav-sidebar-modal {
    top: 25%;
    left: calc(334px + -53.5%);
  }
}

@media only screen and (min-width: 2500px) {
  .nav-sidebar-modal {
    top: 25%;
    left: calc(348px + -53.5%);
  }
}

.card {
  @extend .card;
  @include border-radius;
  background-color: white;

  .card-header {
    @include border-radius;
    border-bottom: 0px;
  }

  .card-footer {
    @include border-radius;
    bottom: 0;
    border: 0;
    width: 100%;
  }
}

.dropdown {
  .dropdown-menu {
    width: max-content;
    box-shadow: 0px 4px 4px rgba(1, 59, 92, 0.16);
    @include border-radius;

    .dropdown-item {
      color: var(--nav-grey5) !important;

      &:hover {
        @include border-radius;
        background-color: var(--nav-grey4) !important;
        color: white !important;
      }
    }

    &.dropdown-menu-alerts {
      width: 280px !important;
    }
  }
}

typeahead-container {
  &.dropdown-menu {

    top: 5px !important;
    z-index: 100000 !important;

    .dropdown-item {

      &.active {
        color: #212529;
        background-color: #f1f3f7;
        cursor: pointer;
      }

      &:hover {
        background: #f1f3f7;
      }
    }
  }
}

.ngx-colors-overlay {
  .opened {
    top: 8px;
    left: -156px;
    width: 340px !important;
  }
}

.ck-powered-by-balloon {
  display: none !important;
}
