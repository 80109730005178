@import "bootstrap/dist/css/bootstrap.min.css";
@import "ngx-bootstrap/datepicker/bs-datepicker.css";

@import "alertifyjs/build/css/alertify.min.css";
@import "alertifyjs/build/css/themes/bootstrap.min.css";

//@import "@fullcalendar/angular/node_modules/@fullcalendar/common/main.css";
//@import "~@fullcalendar/daygrid/main.css";

@import "themes/animations.scss";
@import "themes/theme.scss";
@import "themes/highcharts.css";

/************************************************************
 Bootstrap Theming
 Customizing Bootstrap built-in SASS variables
 All variables are in: _variables.scss
 *************************************************************/

$grid-breakpoints: (
  xxs: 0,
  xs: 375px,
  // phone
  sm: 544px,
  // phone
  md: 768px,
  // tablet
  lg: 992px,
  // desktop
  xl: 1200px,
  // desktop
  xxl: 1600px // wide desktop,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,
);

$container-max-widths: (
  xxs: 0,
  xs: 375px,
  sm: 544px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1600px,
);

/* Do not change this file location, it must be right after all bootstrap variables overwritten  */
@import "bootstrap/scss/bootstrap";

/************************************************************
Common styles for Admin/Main Project
Here we overwrite Bootstrap classes or create new ones
using the @extend SASS function
*************************************************************/

@mixin border-radius {
  border-radius: 0.8rem 0.8rem 0rem 0rem;
}

.card {
  @extend .card;
  @include border-radius;
  box-shadow: 0 0 0 1px #f1f4fa;

  .card-header {
    @include border-radius;
    background-color: transparent;
    border-bottom: 0px;
  }

  .card-footer {
    border-radius: 0;
    bottom: 0;
    width: 100%;
    background-color: var(--or-footer-bg);
    border-top: 2px solid var(--nav-grey1);
  }
}

.img-logo {
  @extend .img-fluid;
}

.far,
.fas {
  cursor: pointer;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-none {
  cursor: none;
}

.cursor-auto {
  cursor: auto;
}

.cursor-default {
  cursor: default;
}

.cursor-na {
  cursor: not-allowed !important;
}

.shadow-sm {
  box-shadow: 0 3px 11px 0 rgba(0, 0, 0, 0.1) !important;
}

.shadow-md {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.1) !important;
}

.shadow-lg {
  box-shadow: 0 7px 4px rgba(0, 0, 0, 0.1) !important;
}

.progress-bar {
  background-color: var(--nav-success);
}

.vl {
  border-left: 1px solid rgba(0, 0, 0, 0.125);
  min-height: 50px;
  padding: 2px;
}

.display-5 {
  font-size: 2.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-6 {
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-7 {
  font-size: 14px;
  font-weight: 300;
  line-height: 1.2;
}

.lead {
  font-size: 1.2rem;
  font-weight: 300;
}

.opacity-0 {
  opacity: 0;
}

.opacity-10 {
  opacity: 0.1;
}

.opacity-20 {
  opacity: 0.2;
}

.opacity-30 {
  opacity: 0.3;
}

.opacity-40 {
  opacity: 0.4;
}

.opacity-50 {
  opacity: 0.5;
}

.opacity-60 {
  opacity: 0.6;
}

.opacity-70 {
  opacity: 0.7;
}

.opacity-80 {
  opacity: 0.8;
}

.opacity-90 {
  opacity: 0.9;
}

.opacity-100 {
  opacity: 1;
}

.overflow-x {
  overflow-x: auto;
}

.overflow-y {
  overflow-y: auto;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

label.required:after {
  color: var(--danger);
  content: "*";
  position: absolute;
  margin-left: 5px;
}

.form-control.is-valid,
.form-control.is-invalid {
  border-color: var(--nav-grey1) !important;
}

select.form-control.is-valid,
select.form-control.is-invalid {
  background-position: right calc(0.9em + 0.2rem) center;
}

.btn-group-xs>.btn,
.btn-xs {
  padding: 0.4rem 0.4rem;
  font-size: 0.7rem;
  line-height: 0.7rem;
  border-radius: 0.2rem;
}


input:focus,
textarea:focus,
button:focus,
select:focus {
  outline: none !important;
  box-shadow: none !important;
  border-color: #ced4da !important;
}

// This is how we override Bootstrap media queries and set our own classes if needed
// @include media-breakpoint-down(sm) {
//   .font-sm-small {
//     font-size: 11px !important;
//   }
// }

/************************************************************
BsModal
*************************************************************/
.modal-content {
  @extend .modal-content;
  border: none;
  border-top-left-radius: 1.3rem;
  border-top-right-radius: 1.3rem;
}

.modal-header {
  border-radius: 0.8rem 0.8rem 0rem 0rem;
  background-color: var(--or-primary-bg);
  color: white;
}

@media (min-width: 544px) {
  .modal-lg {
    max-width: 1200px;
  }

  .modal-md {
    max-width: 700px;
  }

  .modal-sm {
    max-width: 350px;
  }

  .modal-full {
    max-width: 95%;
    height: 90vh;

    .modal-body {
      max-height: 90vh;
      overflow: scroll;
    }
  }
}

.projects {
  &.modal-lg {
    max-width: 900px;
  }
}

/************************************************************
Scrollbar
*************************************************************/
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  border-radius: 3px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  border-radius: 3px;
  -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
  box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
  background-color: #5555556b;
}

/************************************************************
Alertify Style
************************************************************/

.alertify-notifier {
  width: auto;

  .ajs-message {
    display: flex;
    width: unset;
    align-items: center;
    color: var(--nav-grey5);
    font-family: 'Lato';
    padding: 8px 16px 8px 16px !important;
    border: 2px;
    border-radius: 10px;
    border: 2px solid rgba(0, 119, 188, 0.80);
    background: #F7FAFC;
    font-size: 16px;
    text-shadow: none;

    i {
      color: rgba(0, 119, 188, 0.80);
    }

    &.ajs-success {
      color: var(--nav-grey5);
      font-family: 'Lato';
      background: #F8FFF8;
      padding: 8px 16px 8px 16px;
      border: 2px;
      border-radius: 10px;
      border: 2px solid rgba(50, 186, 49, 0.80);
      font-size: 16px;
      text-shadow: none;

      i {
        color: rgba(50, 186, 49, 0.80);
      }
    }

    &.ajs-error {
      color: var(--nav-grey5);
      font-family: 'Lato';
      padding: 8px 16px 8px 16px;
      border: 2px;
      border-radius: 10px;
      border: 2px solid rgba(208, 35, 37, 0.80);
      background: #F9F4F5;
      font-size: 16px;
      text-shadow: none;

      i {
        color: rgba(208, 35, 37, 0.80);
      }
    }

    &.ajs-warning {
      color: var(--nav-grey5);
      font-family: 'Lato';
      padding: 8px 16px 8px 16px;
      border: 2px;
      border-radius: 10px;
      border: 2px solid rgba(254, 204, 0, 0.80);
      background: #FFFEF9;
      font-size: 16px;
      text-shadow: none;

      i {
        color: rgba(254, 204, 0, 0.80);
      }
    }

    &.ajs-visible {
      bottom: 13px !important;
      left: 0 !important;
    }

  }
}

.alertify {
  .ajs-dialog {
    border: none;
    margin: 2% auto;
    animation: fadeDown 0.5s !important;

    .ajs-header {
      background-color: var(--or-primary-bg);
      color: white;
    }

    .ajs-footer {
      // background-color: var(--or-gray-bg);
      border-top: none;
      padding-top: 0;

      .ajs-buttons {
        text-align: center;

        button {
          color: #fff;
          min-width: 55px;
          background-color: #6c757d;
          border: 1px solid #6c757d;
          display: inline-block;
          font-weight: 400;
          text-align: center;
          white-space: nowrap;
          vertical-align: middle;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          // border: 1px solid transparent;
          padding: 0.375rem 0.75rem;
          font-size: 1rem;
          line-height: 1.5;
          border-radius: 0.25rem;
          transition: color 0.15s ease-in-out,
            background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
            box-shadow 0.15s ease-in-out;

          &.ajs-ok {
            background-color: white;
            color: #6c757d;
          }
        }
      }
    }

    .ajs-close {
      background-color: white;
    }
  }
}
