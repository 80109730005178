/************************************************************
Global Styles for Theming

>> :root pseudo-class matches the root element of the document. In HTML :root is the <html> element
>> We can create CSS properties inside a custom-class to use that more specifically
>> We use var() to read a CSS property
>> fallback is acceptable: var(--main-color, #333); (#333 is a fallback)
>> CSS properties are case-sensitive, so --foo and --FOO are different
>> We are using SASS variables and CSS properties together to create a more powerful code

BEM Naming Convention (Block, Element, Modifier)
Source: http://getbem.com/naming

Block is an independent page component that can be reused represented by class attribute in HTML.
Element is a composite part of a block that can't be used separately from it.
Modifier defines the appearance, state or behavior of a block element.


/************************************************************
 Custom SCSS variables for Theming / Branding
*************************************************************/

$body-bg: #f0f3f7;
$body-color: #000;
$success: #7db713; // OR Logo green
$info: #148bca; // OR Logo blue
$danger: #e02d38; // OR Logo red
$box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.3);

$or-primary: #4076b0;
$or-primary-bg: #4076b0;
$or-primary-link: #2d67a6;

$or-secondary: #333333;
$or-secondary-bg: #6c757d;
$or-secondary-link: #333333;

$or-title: #2d67a6;
$or-subtitle: #6a6a6a;
$or-border: #0000001a;
$or-footer-bg: #ffffff;
$or-progress-bar: #3fbd93;
$or-line: #273a54;
$or-dottedline: #0000001a;
$or-gray-bg: #f8f8f8;
$or-lightgray-bg: #dddddd;
$or-darkgray-bg: #aaaaaa;

:root {
  --or-primary: #{$or-primary};
  --or-primary-bg: #{$or-primary-bg};
  --or-primary-link: #{$or-primary-link};
  --or-secondary: #{$or-secondary};
  --or-secondary-bg: #{$or-secondary-bg};
  --or-secondary-link: #{$or-secondary-link};
  --or-footer-bg: #{$or-footer-bg};
  --or-title: #{$or-title};
  --or-gray-bg: #{$or-gray-bg};
  --or-lightgray-bg: #{$or-lightgray-bg};
  --or-darkgray-bg: #{$or-darkgray-bg};
  --or-line: #{$or-line};
  --or-dottedline: #{$or-dottedline};
  --or-border: #{$or-border};
  --or-progress-bar: #{$or-progress-bar};
  --ck-z-modal: 999999 !important;

  --nav-primary: #f6f8fd;
  --nav-secondary: #013b5c;
  --nav-accent-blue: #0077bc;
  --nav-success: #32ba31;
  --nav-warning: #fecc00;
  --nav-error: #d02325;
  --nav-neutral: #101010;

  --nav-grey1: #e0e0e0;
  --nav-grey2: #b5b8bd;
  --nav-grey3: #889095;
  --nav-grey4: #5d6a7d;
  --nav-grey5: #464c54;
  --nav-grey6: #333333;

  --nav-font10: 10px;
  --nav-font12: 12px;
  --nav-font14: 14px;
  --nav-font16: 16px;
  --nav-font18: 18px;
  --nav-font20: 20px;
  --nav-font24: 24px;
  --nav-font36: 36px;

  --nav-black1: #1b1111;
  --nav-black2: #0f0a0a;

  --nav-true-white: #ffffff;
  --nav-snow-white: #fcfcfc;

  --nav-hover-accent: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0.3)
    ),
    #0077bc;
  --nav-accent-button-active: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.3),
      rgba(255, 255, 255, 0.3)
    ),
    #0077bc;
  --nav-dark-mode-secondary: #00304c;
}

/************************************************************
 Custom OR CSS classes
*************************************************************/

.nav-font10 {
  font-size: var(--nav-font10) !important;
}
.nav-font12 {
  font-size: var(--nav-font12) !important;
}
.nav-font14 {
  font-size: var(--nav-font14) !important;
}
.nav-font16 {
  font-size: var(--nav-font16) !important;
}
.nav-font18 {
  font-size: var(--nav-font18) !important;
}
.nav-font20 {
  font-size: var(--nav-font20) !important;
}
.nav-font24 {
  font-size: var(--nav-font24) !important;
}
.nav-font36 {
  font-size: var(--nav-font36) !important;
}

.grey1 {
  color: var(--nav-grey1);
}
.grey2 {
  color: var(--nav-grey2);
}
.grey3 {
  color: var(--nav-grey3);
}
.grey4 {
  color: var(--nav-grey4);
}
.grey5 {
  color: var(--nav-grey5);
}
.grey6 {
  color: var(--nav-grey6);
}
.nav-black1 {
  color: var(--nav-black1);
}
.nav-black2 {
  color: var(--nav-black2);
}
.nav-neutral {
  color: var(--nav-neutral);
}

.or-primary {
  color: var(--or-primary);
}
.or-primary-bg {
  background-color: var(--or-primary-bg) !important;
}
.or-primary-link {
  color: var(--or-primary-link) !important;
  font-weight: 500;
  cursor: pointer;

  &:hover {
    text-decoration: underline !important;
  }
}
.or-outline-primary-bg {
  color: var(--or-primary-bg) !important;
  border-color: var(--or-primary-bg) !important;

  &:hover {
    color: #fff !important;
    background-color: var(--or-primary-bg);
  }
}

.or-secondary {
  color: var(--or-secondary) !important;
}
.or-secondary-bg {
  background-color: var(--or-secondary-bg) !important;
}
.or-secondary-link {
  color: var(--or-secondary-link) !important;
  font-weight: 500;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.dotted-underline {
  &:hover {
    text-decoration: underline !important;
    text-decoration-style: dotted !important;
  }
}

.or-dotted-border {
  border: 2px dotted var(--or-dottedline);
}

.or-title {
  color: var(--or-title);
}

.or-gray-bg {
  background-color: var(--or-gray-bg) !important;
}

.or-darkgray-bg {
  background-color: var(--or-darkgray-bg) !important;
}

.or-line {
  background-color: var(--or-line);
}

.or-dottedline {
  border-top: 3px dotted var(--or-dottedline);
}

.nav-leftborder {
  border-left: 2px solid var(--nav-grey1);
}

// DROPDOWN STATUS
.or-dropdown-status {
  span {
    padding: 2px 10px;
    display: inline-flex;
    border-radius: 10px;
    color: var(--nav-grey5);
    height: 26px;
    gap: 8px;
    align-items: center;
  }
}

// DEFAULT CHECKBOX
.or-checkbox {
  display: flex;
  justify-content: center;
  width: 100%;

  input[type="checkbox"] {
    opacity: 0;
    display: none;
    box-sizing: border-box;

    &:checked + label::before {
      font-family: "Font Awesome 6 Pro";
      content: "";
      text-align: center;
      font-weight: 600;
      line-height: 1.6;
      background-color: var(--or-primary-bg);
      //border-color: var(--or-primary-bg);
      color: white;
    }

    &:disabled + label::before {
      background-color: #e9ecef;
    }
  }

  label {
    display: inline-block;
    position: relative;
    cursor: pointer;
    font-size: 12px;
    color: var(--or-secondary);

    &:before {
      content: "";
      display: inline-block;
      position: absolute;
      width: 22px;
      height: 22px;
      top: 0px;
      left: 0px;
      border: 1px solid #ccc;
      border-radius: 3px;
      background-color: #fff;
      transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
    }

    span {
      margin-left: 30px;
    }
  }
}

// NGX ACCORDION
.modal-content {
  .panel {
    margin-bottom: 5px;

    .panel-default {
      border-radius: 0.25rem;
    }
  }
}
